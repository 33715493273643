import React from 'react'

import { Button, InnerContent } from '@/atoms'
import { SpacerBase } from '@/atoms/Grid'
import { Heading } from '@/atoms/Typography'

import * as S from './styles'

const ArchiveSidebar = ({
  entity,
  tagline,
  title,
  description,
  categories = [],
}) => {
  return (
    <S.SidebarWrapper>
      {tagline ? (
        <>
          <InnerContent
            content={tagline}
            typo='label1'
            bold
            uppercase
          />
          <SpacerBase xs={2} />
        </>
      ) : null}
      {title ? (
        <>
          <InnerContent
            {...title}
            bold
          />
          <SpacerBase xs={2} />
        </>
      ) : null}
      {description ? (
        <>
          <InnerContent {...description} />
          <SpacerBase xs={2} />
        </>
      ) : null}
      {categories.length > 0 ? (
        <>
          <Heading
            typo='label2'
            bold
            uppercase
          >
            Scopri di più su
          </Heading>
          <SpacerBase xs={2} />
          {categories.map((el) => {
            return (
              <S.SidebarButton key={`sidebar-btn-cat-${el.id}`}>
                <Button
                  label={`Vai all'elenco ${el.name}`}
                  href={`/${entity}/${el.slug}`}
                  iconStart={el.icon}
                  fifth
                  inheritedColorScheme='primaryLight'
                  aria-current={el.isActive ? true : false}
                />
              </S.SidebarButton>
            )
          })}
        </>
      ) : null}
    </S.SidebarWrapper>
  )
}

export default ArchiveSidebar
