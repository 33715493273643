import React from 'react'

import { Button } from '@/atoms'
import { Col, Row, SpacerBase } from '@/atoms/Grid'
import { Heading } from '@/atoms/Typography'

import Accordion from '@/molecules/Accordion'
import Card from '@/molecules/Card'

import * as S from './styles'

const ArchiveList = ({
  entity,
  type,
  categories = [],
  ctaLabel,
  minimizedList = false,
  listSlice = 5,
  backButton = false,
  backButtonLabel,
}) => {
  const backButtonData = {
    label: backButtonLabel,
    href: `/${entity}`,
    iconStart: 'chevron-left',
    secondary: true,
    ariaLabel: 'Torna indietro'
  }

  return (
    <S.ListWrapper>
      {categories.length > 0
        ? categories.map((cat) => {
            let list = minimizedList ? cat.items.slice(0, listSlice) : cat.items
            let buttonData = {
              label:
                entity == 'set-informativi'
                  ? `Consulta tutti i set informativi`
                  : `${cat.name}: ${ctaLabel}`,
              ariaLabel:
                entity == 'set-informativi'
                  ? `Consulta tutti i set informativi: ${cat.name}`
                  : `${cat.name}: ${ctaLabel}`,
              href: `/${entity}/${cat.slug}`,
              iconEnd: 'chevron-right',
              secondary: true,
            }
            let button = backButton ? backButtonData : buttonData

            return (
              <S.ListItem key={cat.id}>
                <S.ListHead>
                  <Heading
                    as='h2'
                    typo='displayXS'
                    bold
                  >
                    {cat.name}
                  </Heading>
                  <SpacerBase xs={2} />
                  <Button
                    {...button}
                    inheritedColorScheme='primaryLight'
                  />
                </S.ListHead>
                <S.ListContent>
                  {type == 'accordion' ? (
                    <Accordion list={list} />
                  ) : type == 'grid' ? (
                    list.length > 0 ? (
                      <Row>
                        {list.map((item, i) => (
                          <Col
                            key={`archiveListItem-document-${i}`}
                            xs={12}
                            md={4}
                          >
                            <Card
                              data={item}
                              type='document'
                            />
                          </Col>
                        ))}
                      </Row>
                    ) : null
                  ) : null}
                </S.ListContent>
              </S.ListItem>
            )
          })
        : null}
    </S.ListWrapper>
  )
}

export default ArchiveList
